<template>
  <div class="blogs">
    <swiper :banner-list="bannerList"></swiper>
    <div class="blogsBox">
      <blogsList :archives-list="archivesList"></blogsList>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import swiper from "./../components/swiper.vue";
import blogsList from "./../components/blogsList.vue";
import { toTop } from "./../common/top.mixin.js";

export default {
  name: "blogs",
  mixins: [toTop],
  components: {
    swiper,
    blogsList
  },
  data() {
    return {
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      bannerList:[]
    };
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.blogsSwiper();
    this.getArchives();
  },
  computed: {
  },
  methods: {
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getArchives();
      this.backTop();
    },
    async getArchives() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: this.currentPage,
          channel: -1,
          model: 1,
          menu_index: 2
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList, channel } = res.data;
        this.channel = channel;
        this.total = pageList.total;
        this.archivesList = [...pageList.data];
      } catch (err) {
        console.log(err);
      }
    },
    // 获取blogs轮播图图片
    async blogsSwiper() {
      try {
        let params = {
          type:'blogsSwiper'
        }
        let res = await this.$api.https.getblocklist(params);
        // console.log("头部请求数字", res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.bannerList = res.data;
      } catch (error) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.blogs {
  background: #ffffff;
  .blogsBox {
    padding: 40px 50px 0 50px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $vue_color;
      font-size: 24px;
      font-weight: bold;
      .bar {
        width: 20px;
        height: 2px;
        background: $vue_color;
        margin-top: 12px;
      }
    }
  }
}
</style>
